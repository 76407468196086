import TYPES from '@/types';
import { v4 } from 'uuid';
import router from '@/vue-app/router';
import i18n from '@/vue-app/plugins/i18n';
import {
  requiredRule,
  emailFormat,
  RuleResponseType,
} from '@/vue-app/utils/form-rules';

// Application
import SearchStepNewClientWasAddedQuery
  from '@/modules/onboarding/on-boarding-steps/application/queries/search-step-new-client-was-added-query';
import CreateUserPasswordVerifyTokenQuery
  from '@/modules/onboarding/on-boarding-steps/application/queries/create-user-password-verify-token-query';
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';
import RegisterUserServiceCommand
  from '@/modules/register/user/application/commands/register-user-command-service';
import { SignInCommand } from '@/modules/authentication/application/commands';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import OnBoardingVerifyEmailExistenceQuery
  from '@/modules/onboarding/email/application/queries/on-boarding-verify-email-existence-query';
import GetCustomerGroupIdQuery
  from '@/modules/group/application/queries/get-customer-group-id-query';

// Domain
import { StepNewClientWasAddedDto }
  from '@/modules/onboarding/on-boarding-steps/domain/dtos/step-new-client-was-added-dto';
import { CustomerGroupDto }
  from '@/modules/group/domain/dtos/customer-group-dto';
import { StepNewClientWasAddedEntity }
  from '@/modules/onboarding/on-boarding-steps/domain/entities/step-new-client-was-added-entity';
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import PasswordValidator from '@/modules/authentication/domain/services/validate/password-validator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator,
  noConsecutiveCharactersValidator,
  noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/user/domain/services/password-validators';

export default class UserCreatePasswordViewModel {
  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  private readonly get_internet_status_query!: GetInternetStatusQuery;

  @Inject(TYPES.SEARCH_STEP_NEW_CLIENT_WAS_ADDED_QUERY)
  private readonly search_step_new_client_was_added_query!: SearchStepNewClientWasAddedQuery;

  @Inject(TYPES.REGISTER_USER_COMMAND_SERVICE)
  private readonly register_user_command!: RegisterUserServiceCommand;

  @Inject(TYPES.SIGN_IN_COMMAND)
  private readonly sign_in_command!: SignInCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  private readonly search_agreements_async_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnboardingStepCommand;

  @Inject(TYPES.ON_BOARDING_VERIFY_EMAIL_EXISTENCE_QUERY)
  private readonly on_boarding_verify_email_existence_query!: OnBoardingVerifyEmailExistenceQuery;

  @Inject(TYPES.CREATE_USER_PASSWORD_VERIFY_TOKEN_QUERY)
  private readonly create_user_password_verify_token_query!: CreateUserPasswordVerifyTokenQuery;

  @Inject(TYPES.GET_CUSTOMER_GROUP_ID_QUERY)
  private readonly get_customer_group_id_query!: GetCustomerGroupIdQuery;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly login_path = `${process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL}`;

  private get internet_status() {
    return this.get_internet_status_query.execute();
  }

  private readonly step_ids = {
    sign_up: v4(),
    verify_nip: v4(),
  };

  inputs = {
    email: '',
    password: '',
    confirm_password: '',
    accept_terms_and_conditions: false,
    accept_notice_of_privacy: false,
    accept_digital_media: false,
  };

  is_the_information_correct = false;

  inputs_config = {
    email: {
      rules: [requiredRule, emailFormat],
    },
    password: {
      rules: [
        requiredRule,
        (value: string) => (
          PasswordValidator.validate(value, [this.inputs.email.split('@')[0]])
          || i18n.t('shared.form-rules.password_format').toString()
        ),
      ],
    },
    confirm_password: {
      rules: [
        (value: string): RuleResponseType => value === this.inputs.password || 'Las contraseñas no coinciden.',
      ],
    },
  };

  user_agreements: Array<CustomerAgreementEntity> = [
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
  ];

  is_open_modal = {
    terms_and_conditions: false,
    notice_of_privacy: false,
    digital_media: false,
    existent_email: false,
  };

  modal_error = {
    title: 'Error al registrarse',
    message: 'Verifica tu información e inténtalo nuevamente',
    is_open: false,
  };

  is_valid_form = false;

  is_loading = false;

  view!: any;

  show_password = {
    password: false,
    password_confirmation: false,
  };

  step_new_client_was_added_dto: StepNewClientWasAddedDto = {
    token: '',
  }

  customer_group_id_dto: CustomerGroupDto = {
    token: '',
  }

  step_new_client_was_added_entity: StepNewClientWasAddedEntity = {
    current_step: '',
    id: '',
    payload: {
      name: '',
      last_name: '',
      second_last_name: '',
      email: '',
      type_of_service_id: '',
      type_of_process_id: '',
    },
  }

  customer_id = '';

  customer_group_id = '';

  show_modal_request_new_link_to_create_password = false;

  is_a_valid_token = true;

  get is_password_min_length_valid() {
    return minLengthValidator(this.inputs.password);
  }

  get password_contains_upper_case_one_lower_case_and_one_digit() {
    return containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.password);
  }

  get password_contains_columbus_word_or_email() {
    const email_id = this.inputs.email.split('@')[0];
    return blacklistValidator(this.inputs.password, [email_id]);
  }

  get password_has_no_consecutive_characters() {
    return noConsecutiveCharactersValidator(this.inputs.password);
  }

  get password_has_no_consecutive_repeated_characters() {
    return noConsecutiveRepeatedCharactersValidator(this.inputs.password);
  }

  get can_continue() {
    return (
      this.is_valid_form
      && this.inputs.accept_terms_and_conditions
      && this.inputs.accept_notice_of_privacy
      && this.inputs.accept_digital_media
      && !this.is_loading
      && this.internet_status
      && this.is_the_information_correct
      && this.is_a_valid_token
    );
  }

  getTypeOfPasswordTextField = (is_password_confirmation: boolean) => {
    if (is_password_confirmation) {
      return (this.show_password.password_confirmation) ? 'text' : 'password';
    }
    return (this.show_password.password) ? 'text' : 'password';
  }

  getIconTextField = (is_password_confirmation: boolean) => {
    if (is_password_confirmation) {
      return (this.show_password.password_confirmation) ? 'mdi-eye' : 'mdi-eye-off';
    }
    return (this.show_password.password) ? 'mdi-eye' : 'mdi-eye-off';
  }

  getPasswordValidationIconColor = (is_valid: boolean) => (
    (is_valid) ? 'accent' : 'gray'
  )

  public constructor(view: any) {
    this.view = view;
  }

  initialize = async (token: string) => {
    this.step_new_client_was_added_dto.token = token;
    this.customer_group_id_dto.token = token;
    await this.isAValidToken();
    if (this.is_a_valid_token) {
      await this.searchStepNewClientWasAddedInformation();
      await this.loadCustomerGroupId();
    }
  }

  changeValueOfShowPassword = (is_password_confirmation: boolean) => {
    if (is_password_confirmation) {
      this.show_password.password_confirmation = !this.show_password.password_confirmation;
    } else {
      this.show_password.password = !this.show_password.password;
    }
  }

  openModalTermsAndConditions = () => {
    this.is_open_modal.terms_and_conditions = true;
  }

  openModalNoticeOfPrivacy = () => {
    this.is_open_modal.notice_of_privacy = true;
  }

  openModalDigitalMedia = () => {
    this.is_open_modal.digital_media = true;
  }

  acceptTermsAndConditions = (accept: boolean) => {
    this.inputs.accept_terms_and_conditions = accept;
    this.user_agreements[0].was_accepted = accept;
    this.user_agreements[0].accepted_on = this.datetime_value.create();
  }

  acceptNoticeOfPrivacy = (accept: boolean) => {
    this.inputs.accept_notice_of_privacy = accept;
    this.user_agreements[1].was_accepted = accept;
    this.user_agreements[1].accepted_on = this.datetime_value.create();
  }

  acceptDigitalMedia = (accept: boolean) => {
    this.inputs.accept_digital_media = accept;
    this.user_agreements[2].was_accepted = accept;
    this.user_agreements[2].accepted_on = this.datetime_value.create();
  }

  searchStepNewClientWasAddedInformation = async () => {
    try {
      const response = await this.search_step_new_client_was_added_query
        .execute(this.step_new_client_was_added_dto);
      this.step_new_client_was_added_entity = { ...response[0] };
      this.inputs.email = this.step_new_client_was_added_entity.payload.email;
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar su información');
    }
  }

  isAValidToken = async () => {
    try {
      const response = await this.create_user_password_verify_token_query
        .execute(this.step_new_client_was_added_dto);
      if (!response.valid) {
        switch (response.message_error) {
          case 'email already exists':
            this.message_notifier.showErrorNotification('El email ya se encuentra registrado');
            break;
          case 'customer_id already exists':
            this.message_notifier.showErrorNotification('El customer id ya se encuentra registrado');
            break;
          default:
            this.show_modal_request_new_link_to_create_password = true;
            break;
        }
      }
      this.customer_id = response.customer_id;
      this.is_a_valid_token = response.valid;
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar su información');
    }
  }

  saveUser = async () => {
    this.is_loading = true;

    try {
      if (this.customer_group_id) {
        await this.view.$recaptchaLoaded();
        await this.registerUser();
        await this.login();
        await this.fillAgreementsInformation();
        await this.saveOnboardingSteps();
        sessionStorage.setItem('user_id', this.customer_id);
        this.view.$recaptchaInstance.hideBadge();
        await router.push('/onboarding');
      } else {
        this.modal_error.message = 'Ocurrió un error al obtener el id del grupo';
        this.modal_error.is_open = true;
      }
    } catch {
      await this.showModalError();
    } finally {
      this.is_loading = false;
    }
  }

  private registerUser = async () => {
    try {
      const token = await this.view.$recaptcha('login');

      await this.register_user_command.execute({
        group_id: this.customer_group_id,
        id: this.customer_id,
        username: this.inputs.email,
        password: this.inputs.password,
        password_confirmation: this.inputs.confirm_password,
        token,
      });
    } catch {
      this.modal_error.message = 'Ocurrió un error al guardar el registro';
      throw new Error();
    }
  }

  private login = async () => {
    try {
      await this.sign_in_command.execute({
        username: this.inputs.email,
        password: this.inputs.password,
      });
    } catch {
      this.modal_error.message = 'Ocurrió un error al iniciar sesión';
      throw new Error();
    }
  }

  private fillAgreementsInformation = async () => {
    const agreements = await this.search_agreements_async_query.execute();
    const terms_conditions_agreement = agreements.find(
      (item) => item.name === 'terms_and_conditions_columbus',
    );
    const notice_privacy = agreements.find(
      (item) => item.name === 'notice_of_privacy_columbus',
    );
    const digital_consent = agreements.find(
      (item) => item.name === 'digital_media_consent_columbus',
    );

    if (terms_conditions_agreement && notice_privacy && digital_consent) {
      this.user_agreements[0].agreement_type_id = terms_conditions_agreement.id;
      this.user_agreements[0].description = terms_conditions_agreement.description;
      this.user_agreements[1].agreement_type_id = notice_privacy.id;
      this.user_agreements[1].description = notice_privacy.description;
      this.user_agreements[2].agreement_type_id = digital_consent.id;
      this.user_agreements[2].description = digital_consent.description;
    } else {
      this.modal_error.message = 'Ocurrió un error al obtener los convenios';
      throw new Error();
    }
  }

  private saveOnboardingSteps = async () => {
    try {
      await this.create_on_boarding_step_command.execute({
        id: this.step_ids.sign_up,
        current_step: 'sign_up',
        payload: {
          agreements: this.user_agreements,
          default_promoter_employee_id: process.env.VUE_APP_DEFAULT_PROMOTER_EMPLOYEE_ID,
          default_referrer_employee_id: process.env.VUE_APP_DEFAULT_REFERRER_EMPLOYEE_ID,
          default_endorsements_ids: null,
        },
      });
      await this.create_on_boarding_step_command.execute({
        id: this.step_ids.verify_nip,
        current_step: 'verify_nip',
        payload: {
          geolocation: {
            reference: null,
            coordinates: null,
          },
        },
      });
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al guardar el progreso');
    }
  }

  private showModalError = async () => {
    try {
      const email_exists = await this.on_boarding_verify_email_existence_query.execute(
        this.inputs.email,
      );

      if (email_exists) {
        this.is_open_modal.existent_email = true;
      } else {
        this.modal_error.is_open = true;
      }
    } catch {
      this.modal_error.is_open = true;
    }
  }

  private loadCustomerGroupId = async () => {
    try {
      const { id } = await this.get_customer_group_id_query.execute(this.customer_group_id_dto);
      this.customer_group_id = id;
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al obtener el id del grupo');
    }
  }

  toLowerCaseWithoutAccents = (value: string) => {
    const normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.inputs.email = normalized.toLowerCase();
  }

  goToLoginPage = () => {
    window.location.href = this.login_path;
  }
}
