















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import UserCreatePasswordViewModel from '@/vue-app/view-models/pages/user-create-password-view-model';
import FooterSocialNetworks from '@/vue-app/components/onboarding/FooterSocialNetworks.vue';

@Component({
  name: 'UserCreatePassword',
  components: {
    FooterSocialNetworks,
    ModalError: () => import('@/vue-app/components/onboarding/alerts/ModalError.vue'),
    ModalRequestNewLinkToCreatePassword: () => import('@/vue-app/components/onboarding/alerts/ModalRequestNewLinkToCreatePassword.vue'),
    TermsAndConditions: () => import('@/vue-app/components/onboarding/sign-up/TermsAndConditions.vue'),
    NoticeOfPrivacy: () => import('@/vue-app/components/onboarding/sign-up/NoticeOfPrivacy.vue'),
    DigitalMedia: () => import('@/vue-app/components/onboarding/sign-up/DigitalMedia.vue'),
    ExistentEmail: () => import('@/vue-app/components/authentication/ExistentEmail.vue'),
  },
})
export default class UserCreatePassword extends Vue {
  user_create_password_view_model = Vue.observable(new UserCreatePasswordViewModel(this));

  async mounted() {
    if (this.$route?.query?.token) {
      await this.user_create_password_view_model
        .initialize(this.$route?.query?.token.toString());
    } else {
      this.user_create_password_view_model.goToLoginPage();
    }
  }
}
